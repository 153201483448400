.alert{
    border-radius: 8px;
    background-color: $white;
    padding: 1rem;
    text-align: left;
    
    &.alert-info{
        background-color: $primary-container;
        border: 1px solid $on-primary-container;
        color: $on-primary-container;
    }

    &.alert-success{
        background-color: $teriary-container;
        border: 1px solid $on-teriary-container;
        color: $on-teriary-container;
    }

    &.alert-danger{
        background-color: $secondary-container;
        border: 1px solid $on-secondary-container;
        color: $on-secondary-container;
    }

    h2{
        font-size: 16px;
        font-weight: 600;
    }

    h4{
        font-size: 1rem;
        font-weight: 600;
        color: inherit
    }

    p{
        color: inherit;
    }

    ul{
        font-size: 1rem;
        list-style: disc;
        padding-left: 1.25rem;
        margin: 0;

        li{
            padding: 0;
            &:before{
                display: none;
            }
        }
        
    }
}