.timeline-container {
    position: sticky;
    top: -69px;
    width: 100%;
    z-index: 10;
    
    &.active{
        top: 0;
        transition: all 0.4s ease-in-out;
    }

    &.hidden {
        transition: all 0.4s ease-in-out;
    }

    .container{
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }
}

.timeline {
    padding: 0 !important;
    background: $white;
    border-bottom: 1px solid $stroke-primary;

    nav {
        position: relative;
    
        .timeline-arrow {
            box-shadow: none;
            border: none;
            outline: none;
            height: calc(100% - 4px);
            position: absolute;
            color: $primary;
            display: flex;
            align-items: center;
            z-index: 10;
            padding: 0 1rem;
    
            &.left {
                left: 0;
                background: linear-gradient(to right, rgba($white, 1) 0%, rgba($white, 1) 60%, rgba($white, 0) 100%);
    
                @include media-breakpoint-up(sm) {
                    padding: 0 1rem 0 0;
                }
            }
    
            &.right {
                right: 0;
                background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 1) 40%, rgba($white, 1) 100%);
    
                @include media-breakpoint-up(sm) {
                    padding: 0 0 0 1rem;
                }
            }
    
            .SVGInline {
                svg {
                    font-size: 1.25rem;
                }
            }
        }
    
        ul {
            white-space: nowrap;
            margin: 0 1.75rem;
            padding: 0.5rem 0;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
    
            &::-webkit-scrollbar {
                display: none;
                opacity: 0;
            }
    
            &::-webkit-scrollbar-thumb {
                background: $white;
                opacity: 0;
            }
    
            @include media-breakpoint-up(sm) {
                overflow: hidden;
                margin: 0 0.75rem;
            }
    
            &.smooth {
                scroll-behavior: smooth;
            }
    
            .nav-item {
                display: inline-block;

                &:first-child{
                    .nav-link{
                        margin-left: 1rem;
                    }
                }

                &:last-child{
                    .nav-link{
                        margin-right: 1rem;
                    }
                }
    
                .nav-link {
                    position: relative;
                    min-width: 5.5em;
                    text-align: center;
                    border-radius: 8px;
                    color: $menu-items;
                    margin: 0;
                    white-space: nowrap;
                    cursor: pointer;
                    font-size: rem-calc(12);

                    span{
                        color: $on-surface;
                    }

                    svg {
                        width: 10px;
                    }
    
                    &.clicked {
                        background: $light-grey;
                    }
    
                    &.placeholder {
                        color: transparent;
                        margin: 0 1px;
                    }

                    &.active{
                        background: $primary;
                        color: $on-primary;

                        span{
                            color: $on-primary;
                        }
                    }
                    .SVGInline {
                        margin-right: 0.15rem;
                    }
                }
            }
        }
    }
}
