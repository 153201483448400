@charset "UTF-8";

.page-template-fav-meals {
    height: 100%;

    main{
        position: relative;

        .modalContainer{
            transition: transform 500ms;
            transform: translateX(0);
            height: 100%;
        }

        .showModal{
            transform: translateX(-50%);
            overflow: hidden !important;
        }

        // day plan new styles
        .add-remove-fav{
            position: absolute;
            top: 0;
            right: 0;
            margin-top: -5px;
            margin-right: 10px;
            background: none;
            border: none;
            padding: 0;
    
            @include media-breakpoint-up(md){
                margin-right: 25px;
            }
        }

        .meal{
            position: relative;
            margin: 0;

            @include media-breakpoint-down(sm){ 
                padding-left: 6px;

                &:nth-child(2n+1){
                    padding-left: 16px;
                    padding-right: 6px;
                }
            }

            .white-box{
                position: relative;
                padding: 0 8px 10px 8px;
                overflow: hidden;

                .embed-responsive{
                    margin: 0 -8px 10px -8px;
                    width: calc(100% + 16px);

                    .embed-responsive-item{
                        background-size: cover;
                        background-position: center;
                    }
                }

                .meal-title{
                    height: 48px;
                    overflow: hidden;
                    margin: 0 0 16px 0;

                    // trim to 2 lines
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;  

                    @include media-breakpoint-up(md){
                        height: 48px;
                    }
                }
            } 
        }

        .meals-filters{
            position: sticky;
            top: 0;
            background: $white;
            padding: 0;
            z-index: 10;
            box-shadow: 0px 4px 6px rgba($black, 0.1);
            overflow-x: auto;

            &.active {
                transition: all 0.4s ease-in-out;
            }
            
            &.hidden {
                top: 0;
                transition: all 0.4s ease-in-out;
            }

            .container{
                padding: 0;
            }

            ul{
                text-align: center;
                white-space: nowrap;
                margin: 0;
                padding: 8px;
                overflow-x: auto;

                @include media-breakpoint-up(sm) {
                    overflow: hidden;
                }

                .nav-item{
                    display: inline-block;
                }
                    
            }

            .nav-link{
                padding: 6px 12px;
                color: $paragraph-light;
                margin: 0;
                font-size: 14px;
                white-space: nowrap;
                cursor: pointer;
                border-radius: $border-radius;

                &:active, &:hover{
                    color: $primary;
                }

                &.active{
                    color: $white;
                    background: $primary;
                } 
            }
        }

        //meal-details
        .meal-details {
            padding-top: 0;
            padding-bottom: 0;
            position: relative;

            .embed-responsive{
                margin: 0 -15px 24px -15px;
                width: calc(100% + 30px);
    
                @include media-breakpoint-up(md){
                    margin: 0 0 24px 0;
                    width: 100%;
                }
    
                .video{
                    background-color: $grey;
                }
    
                .embed-responsive-item{
                    background-size: cover;
                    background-position: center;
                    border-radius: 0;
                }

                .label{
                    position: absolute;
                    bottom: 1rem;
                    width: auto;
                    display: inline-block;
                    background: $light-grey;
                    border-radius: $border-radius;
                    padding: 4px 24px;
                    font-size: 16px;
                    font-weight: 400;
                    color: $black;
                }
            }

            .dish{
                margin: 0 0 16px 0;
            }

            .macro-and-kcal{
                background: $primary-container;
                padding: 1rem;
                border-radius: $border-radius;
                margin: 0 0 24px 0;
                color: $on-primary-container;
                font-size: 12px;
        
                .dietType-container {
                    position: relative;
                    padding-bottom: 8px;
                    margin-bottom: 16px;
                    font-size: 14px;
                    color: $on-primary-container;
                    
            
                    &::before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        width: 90%;
                        height: 1px;
                        transform: translateX(-50%);
                        background-color: $stroke-primary;
                    }
            
                    strong {
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
        
                h3{
                    font-size: 16px;
                    color: $on-primary-container;
                }
        
                .border-right {
                    border-right: 1px solid $stroke-primary !important;
                }
            }

            .ingredients {
                font-size: 16px;
                list-style: none;
                margin: 0;
                padding: 0;
    
                li {
                    position: relative;
                    margin: 0 0 16px 0;
                    padding-left: 0;
                    color: $paragraph-text-color;
                    cursor: pointer;
                }
            }

            .recipe {
                background: $surface-variant;
                padding: 16px;
                margin: 0 0 16px 0;
                border-radius: 10px;
    
                ol {
                    font-size: 1rem;
                    padding: 0 0 0 1.25rem;
    
                    li {
                        margin-bottom: 1rem;
    
                        &::marker{
                            font-weight: 700;
                            color: $black;
                        }
    
                        @media print {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            footer{
                position: sticky;
                bottom: 0;
                width: 100%;
                padding: 8px 0 !important;
                background: $white;
                box-shadow: 0px -4px 6px rgba($black, 0.1);
            }
        }

        .overlay{
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 99;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            background: rgba($black, 0.5);

            &.view-enter {
                transform: translateY(0);
                opacity: 0;
            }
    
            &.view-enter-active {
                transform: translateY(0);
                opacity: 1;
                transition: all 500ms;
            }
    
            &.view-exit {
                transform: translateY(0);
                opacity: 1;
            }
    
            &.view-exit-active {
                transform: translateY(0);
                opacity: 0;
                transition: all 500ms;
            }

        }

        .select-insert-date{
            text-align: center;
            position: fixed;
            bottom: 0;
            left: 1rem;
            right: 1rem;
            padding: 1rem 0 !important;
            background: $white;
            box-shadow: 0px -4px 6px rgba($black, 0.1);
            border-radius: $border-radius $border-radius 0 0;
            z-index: 100;

            &.view-enter {
                transform: translateY(100%);
            }
    
            &.view-enter-active {
                transform: translateY(0);
                transition: transform 500ms;
            }
    
            &.view-exit {
                transform: translateY(0);
            }
    
            &.view-exit-active {
                transform: translateY(100%);
                transition: transform 500ms;
            }

            p{
                font-size: 12px;
            }

            .btn-white{
                color: $black;
                font-size: 16px;
            }

            label {
                display: inline-block;
                position: relative;
                font-size: 1.2rem;
                font-weight: 500;
                width: auto;
                margin: 0;

                input.hidden-input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    width: 320px;
                    height: 100%;
                    border: 0;
                    overflow: hidden;
                    cursor: pointer;
                    z-index: 10;

                    &::-webkit-calendar-picker-indicator {
                        position: absolute;
                        top: -150%;
                        left: -150%;
                        width: 300%;
                        height: 300%;
                        cursor: pointer;
                    }
                }
            }

            .pointer-events-none{
                pointer-events: none;
            }

            .form-group {
                margin-bottom: 0;
            }
        }

    }
} 