.page-template-make-decision {
    header {
        padding: 6rem 0 2rem 0;
    }

    h3{
        font-weight: 600;
        font-size: 16px;

        .big-number{
            font-size: 30px;
            vertical-align: top;
            line-height: 1;
            font-weight: 600;
            color: $primary;
        }

        strong{
            font-weight: 600;
            color: $black;
        }
    }

    .btn-group {
        padding: 3px;
        background-color: #F5F5F5;
        border-radius: $border-radius;

        .btn {

            &.active {
                border-radius: $border-radius;
            }
        }
    }
}
