.page-template-diet-new {
    height: 100%;

    main{
        header{
            padding: 1rem 0;
        }
    }

    .macro-and-kcal{
        background: $primary-container;
        padding: 1rem;
        border-radius: $border-radius;
        margin: 0 0 24px 0;
        color: $on-primary-container;
        font-size: 12px;

        .dietType-container {
            position: relative;
            padding-bottom: 8px;
            margin-bottom: 16px;
            font-size: 14px;
            color: $on-primary-container;
            
    
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 90%;
                height: 1px;
                transform: translateX(-50%);
                background-color: $stroke-primary;
            }
    
            strong {
                font-size: 16px;
                font-weight: 600;
            }
        }

        h3{
            font-size: 16px;
            color: $on-primary-container;
        }

        .border-right {
            border-right: 1px solid $stroke-primary !important;
        }
    }

    .add-remove-fav{
        position: absolute;
        top: 0;
        right: 0;
        margin-top: -5px;
        margin-right: 10px;
        background: none;
        border: none;
        padding: 0;

        @include media-breakpoint-up(md){
            margin-right: 25px;
        }
    }

    .meal{
        position: relative;
        padding: 0 0 16px 0;
        
        .embed-responsive{
            margin: -16px -16px 16px -16px;
            width: calc(100% + 32px);

            @include media-breakpoint-up(md){
                margin: -16px 0 -16px -16px;
                width: calc(100% + 16px);
                height: calc(100% + 32px);

                &::before{
                    padding-top: 0;
                }
            }

            .embed-responsive-item{
                background-size: cover;
                background-position: center;
            }
        }

        .meal-title{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin: 0 0 16px 0;
            // min-height: 56px;
        }
    }

    // meal view
    .top-bar{
        background: $white;
    }

    .meal-navigation{
        position: sticky;
        top: 0;
        background: $white;
        padding: 0;
        z-index: 10;
        box-shadow: 0px 4px 6px rgba($black, 0.1);
        overflow-x: auto;

        &.active {
            transition: all 0.4s ease-in-out;
        }
        
        &.hidden {
            top: 0;
            transition: all 0.4s ease-in-out;
        }

        .container{
            padding: 0;
        }

        ul{
            text-align: center;
            white-space: nowrap;
            margin: 0;
            padding: 8px;
            overflow-x: auto;

            @include media-breakpoint-up(sm) {
                overflow: hidden;
            }

            .nav-item{
                display: inline-block;
            }
                
        }

        .nav-link{
            padding: 6px 12px;
            color: $paragraph-light;
            margin: 0;
            font-size: 14px;
            white-space: nowrap;
            cursor: pointer;
            border-radius: $border-radius;

            &:active, &:hover{
                color: $primary;
            }

            &.active{
                color: $white;
                background: $primary;
            } 
        } 
    }

    //meal-details
    .meal-details {
        padding-top: 0;
        padding-bottom: 0;
        position: relative;

        .embed-responsive{
            margin: 0 -15px 24px -15px;
            width: calc(100% + 30px);

            @include media-breakpoint-up(md){
                margin: 0 0 24px 0;
                width: 100%;
            }

            .video{
                background-color: $grey;
            }

            .embed-responsive-item{
                background-size: cover;
                background-position: center;
                border-radius: 0;
            }
        }

        .dish{
            margin: 0 0 16px 0;
        }

        .ingredients {
            font-size: 16px;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                position: relative;
                margin: 0 0 16px 0;
                padding-left: 24px;
                color: $paragraph-text-color;
                cursor: pointer;
            }
        }

        .btn-change {
            vertical-align: middle;
            display: inline-block;
            margin: -2px 8px 0 0;
            width: 20px;
            height: 20px;
            padding: 0;
            background: none;
            border: none;
            outline: none;

            .img-fluid {
                vertical-align: top;
            }
        }

        .collapse, .collapsing{
            margin-left: -24px;
        }

        .card {
            position: relative;
            margin-top: 16px;
            border-radius: $border-radius;
            border: none;
            background: $teriary-container;

            label{
                font-weight: 500;
                color: $primary;
            }

            .card-body {
                padding: 1rem;
                font-size: 1rem;
                color: $primary;
                text-align: center;

                .ingredients {
                    text-align: left;
                    color: $font-color;
                    font-size: 1rem;
                    margin: 0;

                    button {
                        background: none;
                        border: none;
                        color: $font-color;
                    }
                }
            }
        }

        .recipe {
            background: $surface-variant;
            padding: 16px;
            margin: 0 0 16px 0;
            border-radius: 10px;

            ol {
                font-size: 1rem;
                padding: 0 0 0 1.25rem;
                color: $paragraph-text-color;

                li {
                    margin-bottom: 1rem;

                    &::marker{
                        font-weight: 700;
                        color: $black;
                    }

                    @media print {
                        margin-bottom: 0;
                    }
                }
            }
        }

        footer{
            position: sticky;
            bottom: 0;
            width: 100%;
            padding: 8px 0 !important;
            background: $white;
            box-shadow: 0px -4px 6px rgba($black, 0.1);
        }
    }

    .change-meal{
        .meal-replacement{
            padding: 12px !important;
            border: 1px solid $surface-variant;
            font-size: 16px;

            &.favMeal{
                border: 1px solid $primary;
            }
            
            .embed-responsive{
                margin: 0 !important;
                width: 100% !important;
                height: 100% !important;
                border-radius: $border-radius;

                .embed-responsive-item{
                    background-size: cover;
                    background-position: center;
                }
            }

            .col-8{
                height: 86px;
                padding-left: 0;
            }

            .col-9{
                max-height: 86px;
                overflow: hidden;
                font-size: 14px;
                // trim to 3 lines
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;  
            }
        }
    }

    .change-day{
        padding-bottom: 1rem;
        
        hr{
            margin: 32px 0;
            border-top: 1px solid $stroke-primary;
        }

        label{
            font-weight: 500;
            color: $primary;
        }

        .white-box{
            padding: 16px;
            border: 1px solid transparent;

            &.holiday{
                border: 1px solid $stroke-primary;

                .SVGInline {
                    position: absolute;
                    right: 0;

                    svg {
                        max-width: initial;
                        width: 65px;
                        height: 65px;
                        // path {
                        //     fill: rgba($color: $torquose, $alpha: .2);
                        // }
                    }
                }
            }
        }

        .event-meal{
            border-radius: $border-radius;
            overflow: hidden;
            margin: 0 0 10px 0;
            font-size: 16px;
            padding: 0;

            .embed-responsive{
                margin: 0 !important;
                width: 100% !important;
                border-radius: $border-radius;

                .embed-responsive-item{
                    background-size: cover;
                    background-position: center;
                }
            }

            .col-9{
                padding-left: 0;
                display: flex;
                align-items: center;

                
                div{
                    overflow: hidden;
                    padding-top: 0px;

                    // trim to 3 lines
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;  
                }
            }
        }

        .list-of-dishes{
            margin: 0;
            padding: 0;
            list-style: none;
            display: inline;

            li{
                display: inline;
            }
        }
    }
    
    //Animations

    @keyframes rotateLeft {
        from {
            transform-origin: center;
            transform: translate3d(0, 0, 1, 0);
        }

        to {
            transform-origin: center;
            transform: rotate3d(0, 0, 1, -540deg);
        }
    }

    .rotateLeft {
        animation-name: rotateLeft;
    }

    @keyframes rotateRight {
        from {
            transform-origin: center;
            transform: rotate3d(0, 0, 1, -540deg);
        }

        to {
            transform-origin: center;
            transform: rotate3d(0, 0, 0);
        }
    }

    .rotateRight {
        animation-name: rotateRight;
    }
} 