@import 'variables';

//bootstrap
@import '~bootstrap/scss/bootstrap';

//fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

//functions
@import 'functions/rem-calc';

//core stylesheet
@import 'layout';
@import 'navigation';
@import 'typography';
@import 'button';
@import 'alert';
@import 'form';
@import 'footer';
@import 'flashMessage';
@import 'cachedDataMessage';

//plugins
@import 'plugins/timeline';
@import 'plugins/chartist';
@import 'plugins/odometer';

//components
@import 'components/chart';
// @import 'components/floating-action-button';
// @import 'components/longInlineLoader';
// @import 'components/healthcircle';
// @import 'components/product';
@import '../Calendar/calendar';
@import 'components/developerOptions';

//page templates
// @import 'pageTemplates/diet';
@import 'pageTemplates/diet-new';
@import 'pageTemplates/dietSettings';
@import 'pageTemplates/login';
@import 'pageTemplates/measurement';
@import 'pageTemplates/settings';
@import 'pageTemplates/shoppingList';
// @import 'pageTemplates/infoPages';
// @import 'pageTemplates/cmsContent';
// @import 'pageTemplates/contact';
@import 'pageTemplates/articles';
@import 'pageTemplates/article';
@import 'pageTemplates/error';
// @import 'pageTemplates/authConfirm';
// @import 'pageTemplates/promoCode';
// @import 'pageTemplates/quiz';
// @import 'pageTemplates/coupon';
// @import 'pageTemplates/newsletter-unsubscribe';
// @import 'pageTemplates/cart';
// @import 'pageTemplates/priceList';
// @import 'pageTemplates/thankYou';
// @import 'pageTemplates/update';
@import 'pageTemplates/makeDecision';
@import 'pageTemplates/hydration';
@import 'pageTemplates/favMeals';
@import 'pageTemplates/achievements';
@import 'pageTemplates/buy';

//utils
// @import 'envIndicator';
