//colors
$black: #000;
$white: #fff;

$primary: #144AEC;
$on-primary: $white;
$primary-container: #F0F3FF;
$on-primary-container: #0037AD;

$secondary: #EB0046;
$on-secondary: $white;
$secondary-container: #FCE9ED;
$on-secondary-container: #BD0042;

$teriary: #16C0A1;
$on-teriary: $white;
$teriary-container: #E7F9F5;
$on-teriary-container: #008068;

$stroke-light: #CECECE;
$stroke-medium: #D9D9D9;
$stroke-dark: #999;
$stroke-primary: #A1B7F7;

$background: $white;
$on-background: $black;
$background-variant: #F5F5F5;
$on-background-variant: $black;
$background-variant-2: #111B74;
$on-background-variant-2: $white; 

$surface: $white;
$on-surface: $black;
$surface-variant: #F5F5F5;
$surface-selected: #111B74;
$on-surface-selected: $white;

$menu-items: #999;

$stroke-light: #F5F5F5;
$stroke-medium: #D9D9D9;
$stroke-dark: #999; 
$stroke-primary: #A1B7F7;

$paragraph-text-color: #666;
$paragraph-light: #999;
$text-inverse: $white;
$success: #15B86A;
$error: #DE000D;

$font-color: $black;
$border-radius: 8px;

$light-grey: $stroke-light;
$grey: $stroke-medium;
$dark-grey: $stroke-dark;

$zIndexEnvIndicator: 16777271;
$zIndexCacheMessage: 1001;
$zIndexFloatingACtion: 1001;
$zIndexFooter: 1000; // .fixed-top (main menu) has 1030
$zIndexMenu: 100;
$zIndexTimeline: 5;
$zIndexLoader: 1;

$primary-gradient: linear-gradient(270deg, #144AEC 0%, #0037AD 100%);
$primary-gradient-2: linear-gradient(270deg, #0037AD 0%, #111B74 100%);
