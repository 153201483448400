@import './elements/range';

.form-group {
    label,
    .label {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: $black;
        margin-bottom: 4px;

        display: block;
        text-align: left;

        &.large {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        sup {
            color: $error !important;
        }

        // label {
        //     color: $black;
        //     font-size: 20px;
        //     font-weight: 600;
        //     display: block;
        //     text-align: left;

        //     sup {
        //         color: $red;
        //     }
        // }

        &.form-check-label {
            position: relative;
            background: $white;
            box-shadow: 0px 2px 8px rgba($black, 0.15);
            border-radius: $border-radius;
            margin: 0 0 8px 0;
            padding: 20px;

            color: $black !important;
            font-size: 16px !important;
            font-weight: 400 !important;

            strong {
                color: $black;
                font-weight: 400;
            }

            p {
                margin-bottom: 0;
            }

            &.active {
                background: $background-variant-2;
                box-shadow: 0px 6px 18px $stroke-dark;

                p {
                    color: $white !important;
                }

                strong {
                    font-weight: 500;
                    color: $white;
                }
            }

            &.small {
                padding: 9px 15px;
            }
        }

        // &.is-invalid {
        //     padding: 1rem 0 1rem 2.5rem !important;
        // }

        sup {
            color: $white;
        }
    }

    .form-control {
        padding: 0;
        border-radius: $border-radius;
        color: $black;
        background: $background-variant;
        border: 1px solid $grey;
        height: auto;
        overflow: hidden;

        &.is-invalid {
            border: 1px solid $error;
        }

        input {
            border: none;
            background: none;
            padding: 0.5rem 1rem;
            min-height: 40px;
            box-shadow: none !important;

            &:disabled {
                cursor: not-allowed;
            }

            &::-webkit-date-and-time-value {
                text-align: left;
            }
        }

        textarea {
            border: none;
            background: none;
            padding: 0.5rem 1rem;

            &:disabled {
                cursor: not-allowed;
            }
        }

        .input-group-text {
            color: $stroke-dark;
            background: none;
            border: none;
        }

        .input-placeholder {
            flex: 1 1 auto;
            padding: 0.5rem 1rem;
        }
    }

    .invalid-feedback {
        color: $red;
        text-align: left;

        li {
            list-style: none;
        }
    }

    svg,
    .SVGInline {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding: 0.75rem;
        pointer-events: none;
        max-width: 3.5rem;
        z-index: 3;
        color: $black;
        // color: $on-primary-container;
    }

    &.is-invalid {
        .invalid-feedback {
            display: block;
        }
    }
}

.select {
    select {
        padding: 0.5rem 1rem !important;
        border: none !important;
        border-radius: $border-radius !important;
        background: transparent !important;
        color: $black;
        font-weight: 400;
        appearance: none;
        height: auto !important;
        cursor: pointer;

        &::-ms-expand {
            display: none;
        }
    }
    &.select-blue {
        background: $primary-container !important;
        border: 1px solid $primary-container !important;
        select {
            color: $on-primary-container !important;
        }
        .SVGInline {
            svg {
                color: $on-primary-container !important;
            }
        }
    }
}

.right {
    .custom-control {
        padding-left: 0;
    }
    .custom-control-label::before,
    .custom-control-label::after {
        left: initial;
        right: 0rem;
    }
}

.custom-radio {
    label {
        font-size: 16px;
    }

    .custom-control-label::before {
        width: 20px;
        height: 20px;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        border: 2px solid #144aec;
        background-color: initial;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        width: 12px;
        height: 12px;
        background: #144aec;
        border-radius: 50%;
        transform: translate(-32%, 32%);
    }
}

.custom-checkbox {
    &.square {
        .custom-control-label {
            &::before {
                border-radius: 4px;
            }
        }
    }
}

.custom-switch {
    &.right {
        padding-left: 0;

        label {
            padding-left: 0;
        }
        .custom-control-label::before,
        .custom-control-label::after {
            left: initial;
            right: 0;
        }
        .custom-control-input:checked ~ .custom-control-label::after {
            transform: translateX(-2px);
        }
        .custom-control-label::after {
            transform: translateX(-22px);
        }
    }
    label {
        padding-left: 20px;
        line-height: 40px;
        font-size: 16px;
    }

    .custom-control-label::before {
        width: 52px;
        height: 32px;
        border-radius: 20px;
    }

    .custom-control-label::after {
        width: 28px;
        height: 28px;
        border-radius: 50%;
    }
    .custom-control-input:checked ~ .custom-control-label::after {
        transform: translateX(20px);
    }
}
.form-switch,
.form-check {
    position: relative;
    padding: 0;
    margin: 0 0 1rem 0;

    .border {
        border-radius: 10px;
        padding: 1rem 1rem 1rem 5rem;

        &.has-error {
            border: 1px solid $red !important;
        }
    }

    label {
        margin: 0;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: $grey;
    }

    input {
        display: none;
    }

    .switch {
        position: absolute;
        width: rem-calc(52);
        height: rem-calc(32);
        top: 1rem;
        left: 1rem;
        background-color: $grey;
        transition: 0.4s;
        border-radius: rem-calc(32);
        cursor: pointer;

        &:before {
            position: absolute;
            content: '';
            height: rem-calc(28);
            width: rem-calc(28);
            left: rem-calc(2);
            bottom: rem-calc(2);
            background-color: $white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
        }

        &.placeholder {
            background-color: $light-grey;
        }
    }

    input:checked ~ .switch {
        background-color: $primary;

        &:before {
            transform: translateX(rem-calc(20));
        }
    }
}

// .invalid-feedback {
//     color: $red;
//     text-align: left;

//     li {
//         list-style: none;
//     }
// }

// .custom-range {
//     position: relative;
//     -webkit-appearance: none;
//     appearance: none;
//     width: 100%;
//     height: 46px;
//     outline: none;
//     padding: 0;

//     &::-webkit-slider-runnable-track {
//         width: 100%;
//         height: 10px;
//         cursor: pointer;
//         background: linear-gradient(
//             to right,
//             rgba($primary, 0.2) 0%,
//             $primary var(--value),
//             $background-variant var(--value),
//             $background-variant 100%
//         );
//         border-radius: 5px;
//     }

//     &::-moz-range-track {
//         width: 100%;
//         height: 10px;
//         cursor: pointer;
//         background: linear-gradient(
//             to right,
//             rgba($primary, 0.2) 0%,
//             $primary var(--value),
//             $background-variant var(--value),
//             $background-variant 100%
//         );
//         border-radius: 5px;
//     }

//     &::-webkit-slider-thumb {
//         -webkit-appearance: none;
//         appearance: none;
//         width: 30px;
//         height: 30px;
//         background: $white;
//         border: 4px solid $orange;
//         cursor: pointer;
//         margin-top: -10px;
//     }

//     &::-moz-range-thumb {
//         width: 22px;
//         height: 22px;
//         background: $white;
//         border: 4px solid $orange;
//         cursor: pointer;
//         margin-top: -10px;
//     }

//     &::before {
//         content: attr(min);
//         position: absolute;
//         left: 0;
//         bottom: -0.5rem;
//         font-size: rem-calc(14);
//         color: $font-color;
//     }

//     &::after {
//         content: attr(max);
//         position: absolute;
//         right: 0;
//         bottom: -0.5rem;
//         font-size: rem-calc(14);
//         color: $font-color;
//     }
// }
