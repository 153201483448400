.page-template-settings {
    main {
        background: $light-grey;
    }

    .white-box {
        
        @include media-breakpoint-up(md) {
            padding: 2rem;
        }

        p:last-of-type {
            margin-bottom: 0;
        }

        a:last-of-type {
            margin-bottom: 0;
        }

        form {
            div:last-of-type {
                margin-bottom: 0;

                &.custom-switch {
                    label {
                        margin-bottom: 0;
                    }
                }
            }
            .form-group {
                div:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    header {
        padding: 2rem 0 0 0;
    }

    .btn-social {
        width: 100%;
    }
}
