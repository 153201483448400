@import '~react-lazy-load-image-component/src/effects/blur.css';

.page-template-article-list {
    header {
        padding: 0 0 2rem 0;

        @include media-breakpoint-up(md) {
            padding: 1rem 0;
        }

        h1 {
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 0;
            text-align: center;
        }
    }
    .categoryFilter {
        box-shadow: 0px 2px 6px rgba($black, 0.1);
    }
    .nav-link {
        border-radius: $border-radius;
        cursor: pointer;

        &.active {
            &:hover {
                color: $white;
            }

            p {
                color: $white;
            }
        }
    }

    .my-masonry-grid {
        display: flex;
        margin-left: -30px; /* gutter size offset */
        width: auto;

        .my-masonry-grid_column {
            padding-left: 30px; /* gutter size */
            background-clip: padding-box;

            .card {
                // display: grid;
                margin-bottom: 30px;
                position: relative;
                text-decoration: none;
                border-radius: $border-radius;
                overflow: hidden;
                max-width: 100%;
                transition: all 0.3s ease-in-out;
                box-shadow: 0px 4px 6px rgba($black, 0.1);

                &.border-primary {
                    border-width: 2px;
                }

                &.placeholder {
                    height: 20rem;
                    cursor: default;
                }

                .thumbImg {
                    position: relative;
                    overflow: hidden;
                }

                .card-body {
                    padding: 1.2rem 1.2rem 1.3rem 1.2rem;

                    .category {
                        font-size: 14px;
                        display: inline;
                        padding: 3px 12px;
                        border-radius: 4px;
                        color: $white;

                        &.diet {
                            background-color: $teriary-container;
                            color: $on-teriary-container
                        }

                        &.tips {
                            background-color: $secondary-container;
                            color: $on-secondary-container;
                        }

                        &.workouts {
                            background-color: $primary-container;
                            color: $on-primary-container;
                        }
                    }

                    .article-teaser {
                        span {
                            color: $font-color;
                        }

                        a {
                            text-decoration: none;
                        }

                        .read-more {
                            color: $primary;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}
