.page-template-shopping-list {
    input[type='date']::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }

    .header-bg {
        position: sticky;
        top: 0;
        text-align: center;
        background-color: $light-grey;
        z-index: 0;
    }

    .full-screen {
        display: flex;
        width: 100%;
        min-height: calc(100vh - 5.5rem);
        flex-direction: column;

        @include media-breakpoint-up(sm) {
            min-height: auto;
        }

        &.container {
            min-height: calc(100vh - 3rem);
        }
    }

    .btn-primary,
    .btn-placeholder {
        min-width: 230px;
    }

    .products-category {
        margin-bottom: 2rem;
    }

    .ingredients {
        list-style: none;
        margin: 0 0 1rem 0;
        padding: 0;

        li > button {
            display: flex;
            justify-content: space-between;
            position: relative;
            margin: 1rem 0;
            padding: 0 0 0 28px;
            cursor: pointer;
            width: 100%;
            max-height: 7rem;
            transition: all 0.3s;
            color: $on-background;
            text-align: left;
            font-size: 16px;

            background: none;
            border: none;
            appearance: none;
            outline: none;

            .float-right {
                float: none;
                white-space: nowrap;
                color: $paragraph-text-color;
                transition: all 0.3s;
            }

            &:before {
                content: '';
                position: absolute;
                top: 2px;
                left: 0;
                width: 18px;
                height: 18px;
                border-radius: 20%;
                border: 1px solid $stroke-dark;
                transition: background-color 0.3s ease-in-out;
                color: $black;
                background-repeat: no-repeat;
                background-position: center;
            }

            &.done {
                &:before {
                    content: '';
                    background-image: url(../../assets/images/shopping-list/checkbox.svg);
                    border-color: $primary;
                    opacity: 0.5;
                }

                color: rgba($primary, 0.5);

                .float-right {
                    color: rgba($primary, 0.5);
                }
            }

            &.hide-down {
                transform: translateY(5rem);
                opacity: 0;
                margin: 0 0 -1rem 0;
                max-height: 0;
            }

            &.hide-up {
                transform: translateY(-7rem);
                opacity: 0;
                margin: -1rem 0 0 0;
                max-height: 0;
            }

            &.show {
                animation-name: show;
                animation-duration: 0.3s;
                overflow: hidden;
            }
        }

        @keyframes show {
            0% {
                max-height: 7rem;
                opacity: 0;
            }

            1% {
                max-height: 0;
            }

            100% {
                max-height: 7rem;
                opacity: 1;
            }
        }
    }

    hr {
        margin: 2rem 0;
        border-top: 1px solid $stroke-medium;
    }

    .bought-section {
        background: $primary-container;
        border-radius: rem-calc(8);
        padding: 0;
    }

    .bought-button {
        margin: 0;
        color: $primary;
        padding: 0.75rem 1rem 0.5rem 1rem;
        position: relative;
        max-width: none;
        width: 100%;
        background: transparent;
        border: none;
        text-transform: inherit;
        text-align: inherit;
        font-weight: 400;
        font-size: 1rem;
        appearance: none;
        text-align: center;

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
            color: $primary;
            background-color: transparent;
            border-color: transparent;
        }

        span.collapse-arrow {
            display: inline-block;
            align-items: center;
            position: relative;
            color: $primary;
            font-size: 1rem;
            height: 100%;
            transition: all 0.3s ease-in-out;
            margin: 0 0.5rem;

            &.open {
                transform: rotate(180deg);
            }
        }
    }

    .card {
        position: relative;
        border: none;
        background: transparent;
        margin: 0;

        .card-body {
            padding: 0 1rem;
            text-align: left;

            .ingredients {
                margin: 0;
            }

            p {
                margin: 0 0 rem-calc(10) 0;
                color: $primary;
                font-style: italic;
                font-size: rem-calc(14);
            }
        }
    }
}
