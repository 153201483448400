.page-template-cms-article {
    header {
        padding: 2rem 0;

        .back-btn {
            position: relative;
            transform: translateX(-50%);
        }
    }

    .article-header {
        padding: 0 0 2rem 0;
        border-bottom: 1px solid #efefef;

        @include media-breakpoint-up(md) {
            padding: 0.8rem 0;
        }

        .article-details {
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 0;
            text-align: center;
            position: relative;
            left: 50%;
            transform: translateX(-100%);
        }
    }

    .category {
        font-size: 14px;
        display: inline-block;
        padding: 3px 12px;
        margin-top: 24px;
        margin-bottom: 20px;
        border-radius: 4px;
        color: $white;

        &.diet {
            background-color: $teriary-container;
            color: $on-teriary-container;
        }

        &.tips {
            background-color: $secondary-container;
            color: $on-secondary-container;
        }

        &.workouts {
            background-color: $primary-container;
            color: $on-primary-container;
        }
    }

    .article-title {
        font-size: 24px;
        font-weight: 900;

        @include media-breakpoint-up(md) {
            font-size: 3rem;
        }
    }

    .article-img {
        margin-bottom: 32px;
        span {
            width: 100%;

            img {
                width: 100%;
            }
        }
    }

    .embed-responsive {
        margin-bottom: 32px;
    }

    .blockquote {
        position: relative;
        padding-left: 5rem;
        border-left: 2px solid $secondary;
        quotes: '\201C''\201D''\2018''\2019';

        &:before {
            content: '“';
            position: absolute;
            font-family: cursive;
            top: -2rem;
            left: 1rem;
            color: $secondary;
            font-size: 6.25rem;
        }
    }

    p.sign {
        position: relative;
        text-align: right;
        margin: 1rem 0 1rem 0;
        color: $blue;
    }

    .article-lead,
    .article-summary {
        margin: 16px 0 32px 0;
        font-size: 1.5rem;
    }

    hr {
        //max-width: 700px;
        margin-left: auto;
        margin-right: auto;
    }

    .content {
        h1 {
            font-weight: 600;
        }

        h2 {
            font-size: 20px;
            font-weight: 600;
        }

        ul, ol {
            li {
                &::before {
                    background: $primary !important;
                }
            }
        }

        &.how-to {
            li {
                &::before {
                    display: block;
                }
                p {
                    display: block;
                    margin-top: 20px;
                }
            }
        }
        .cms-content {
            &.contact,
            &.terms-of-service {
                .card {
                    background-color: $background-variant;
                    border: none;
                }
                p,
                .card-body {
                    font-size: 14px;
                    color: $paragraph-text-color;
                }
            }

            h2,h3 {
                font-size: 20px;
            }

            h4 {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    .cms-content,
    .article-lead {
        //max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;

        @include media-breakpoint-up(md) {
            font-size: 1.5rem;
        }

        p {
            margin-bottom: 32px;

            img {
                margin: 0 auto;
                display: block;
            }
        }

        h1 {
            text-align: center;
        }

        h2 {
            text-align: center;
        }

        h3 {
            color: $black;
        }

        ul {
            padding: 0;
            list-style: none;
            margin-bottom: 0;

            li {
                position: relative;
                padding: 0 0 0 1rem;
                margin: 16px 0;

                @include media-breakpoint-up(md) {
                    font-size: 1.25rem;
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 12px;
                    transform: translateY(-50%);
                    width: 0.5rem;
                    height: 0.5rem;
                    border-radius: 50%;
                    background-color: $secondary;

                    // @include media-breakpoint-up(lg) {
                    //     top: 20px;
                    // }
                }

                &::marker {
                    color: transparent;
                }

                p {
                    margin: 0;
                }
            }
        }

        ol {
            padding: 0;
            list-style: none;
            counter-reset: item;

            li {
                padding: 0;
                counter-increment: item;
                margin-bottom: 15px;

                p {
                    display: inline;

                    ol {
                        padding-left: 20px;
                        padding-top: 30px;
                    }
                }
            }

            li:before {
                margin-right: 10px;
                content: counter(item);
                background: $secondary;
                border-radius: 100%;
                color: $white;
                width: 24px;
                height: 24px;
                line-height: 24px;
                font-style: initial;
                text-align: center;
                display: inline-block;
                font-size: 16px;
            }
        }

        .collapse-item {
            border-bottom: 1px solid $light-grey;

            h3 {
                font-size: 16px;
                margin: 1rem 0;
                padding-right: 2rem;
                position: relative;

                button {
                    background: none;
                    border: none;
                    color: $black;
                    font-weight: 500;
                    text-align: left;
                    display: block;
                    width: 100%;
                    outline: none;
                }

                span.collapse-arrow {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    right: 0;
                    top: 0;
                    color: $primary;
                    height: 100%;
                    transition: all 0.3s ease-in-out;

                    &.open {
                        transform: rotate(180deg);
                    }
                }
            }

            &:last-child {
                border-bottom: none;
            }
        }

        table {
            font-size: 16px;
            color: $black;


            p {
                color: $black;
            }

            th,
            td {
                padding: 0.5rem;
            }
        }

        .pageActions {
            text-align: center;
        }

        aside {
            &.right {
                float: right;
                margin-left: 1rem;
                margin-bottom: 1rem;
            }

            &.left {
                float: left;
                margin-right: 1rem;
                margin-bottom: 1rem;
            }
        }
    }
    .product {
        border-radius: $border-radius;
        padding: 10px;
        background-color: $primary-container;
        color: $black;

        .product-bestseller {
            text-align: center;
            font-size: 36px;
        }

        .card-header {
            background: transparent;
            border-bottom: none;
            font-weight: bold;
            font-size: 24px;
            line-height: 34px;
            text-align: center;
        }

        .card-body {
            flex-direction: column;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 390;
            padding: 0;

            .product-price {
                font-size: 36px;
                line-height: 51px;
            }

            .product-description {
                font-size: 24px;
                line-height: 34px;
            }
        }

        .card-footer {
            background: transparent;
            border-top: none;
            margin: 0 auto;
        }
    }
}
