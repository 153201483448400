footer{
    background: $light-grey;
	position: relative; 
    padding: 1.5rem 0 !important;
	border-top: 1px solid $light-grey;
	margin-top:auto;
	
	.nav-link{
		font-size: 14px;
		font-weight: 400;
		color: $font-color;
		padding: 0 0 1rem 0;

		&:hover{
			text-decoration: underline;
		}
	}

	.nav-item:last-child{
		.nav-link{
			padding: 0;
		}
	}

	.logo{
		margin: 0 1rem 0 0;
	}

	.dropdown-arrow {
		color: $primary;
		display: inline-block;
		transition: all 0.3s ease-in-out;

		&.open {
			transform: rotate(180deg);
		}
	}

	.avalibility{
		opacity: 0.3;
		font-size: rem-calc(12);
		margin-right: 0;
		margin-bottom: 1rem;
		display: inline-block;
	}

	.social-link{
		display: inline-block;
		background-color: $primary;
		border-radius: 50%;
		margin: 0 1rem 1rem 0;
		transition: background-color 0.3s ease-in-out;

		&:hover{
			background-color: $primary;
		}
	}
}