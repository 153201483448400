.page-template-login{
	position: relative;


	.bg-white {
		padding-top: 3rem;
		margin-top: 3rem;
	}

	h1 {
		font-size: 20px;
		font-weight: 600;
	}

	section{
		padding: 2rem 0;
	}

	.btn-primary{
		width: 100%;
	}
}