.featured-achievement {
    position: relative;
    padding: 3.5rem 0 2rem 0;
    background: $white;
    color: $black;
    border-radius: 15px;
    text-align: center;
    overflow: hidden;

    &.is-achieved {
        img,
        .bg {
            opacity: 1;
        }
    }

    img {
        position: relative;
        margin: 0 0 8px 0;
        opacity: 0.3;
        max-width: 200px;
        height: auto;
        transition: opacity 0.3s;
    }

    .bg {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: none;
        width: 330px;
        height: 330px;
        transform: rotate(0deg) translate(-50%, -68%);
        animation: spin 12s infinite;
        animation-timing-function: linear;
        opacity: 0;
        transition: opacity 0.3s;
    }


    @keyframes spin {
        0% {
            transform: translate(-50%, -68%) rotate(0deg);
        }
        100% {
            transform: translate(-50%, -68%) rotate(360deg);
        }
    }
}

.page-template-achievements {
    background-color: $light-grey;
    main {
        background-color: $light-grey;
        section {
            padding: 2rem 0;
        }

        .dropdown-arrow {
            color: $primary;
            display: inline-block;

            &.up {
                transform: rotate(180deg);
            }
        }

        h2 {
            margin: 0 0 20px 0;
        }

        .achieve-box {
            padding: 24px 8px 16px;
            background-color: $white;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: $border-radius;
        }

        .achieve-wrapper {
            padding-top: 0;
        }

        .achieve-wrapper:first-child {
            padding-top: 3rem;
        }

        .achievement {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: transparent;
            border: 1px solid transparent;
            border-radius: $border-radius;
            text-align: center;
            padding: 8px 4px;
            margin: 0 0 20px 0;
            cursor: pointer;

            &.active {
                background-color: $light-grey;
                border: 1px solid $grey;
            }

            img {
                opacity: 0.3;
                margin: 0 0 4px 0;
                max-width: 78px;
                height: auto;
            }

            &.is-achieved {
                img {
                    opacity: 1;
                }
            }

            // .achievement-date {
            //     display: block;
            //     font-size: 10px;
            //     color: $dark-grey;
            //     font-weight: 500;
            // }
        }
    }
}
