h1,
.heading-1 {
    font-size: 48px;
    line-height: 1.2;
    font-weight: 400;
    margin-bottom: 1rem;

    &.bold {
        font-weight: 700;
    }

    &.semi-bold {
        font-weight: 600;
    }

    &.medium {
        font-weight: 500;
    }
}

h2,
.heading-2 {
    font-size: 40px;
    line-height: 1.2;
    font-weight: 400;
    margin-bottom: 1rem;

    &.bold {
        font-weight: 700;
    }

    &.semi-bold {
        font-weight: 600;
    }

    &.medium {
        font-weight: 500;
    }
}

h3,
.heading-3 {
    font-size: 30px;
    line-height: 1.2;
    font-weight: 400;
    margin-bottom: 1rem;

    &.bold {
        font-weight: 700;
    }

    &.semi-bold {
        font-weight: 600;
    }

    &.medium {
        font-weight: 500;
    }
}

.title-large {
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;

    &.bold {
        font-weight: 700;
    }

    &.semi-bold {
        font-weight: 600;
    }

    &.medium {
        font-weight: 500;
    }
}

.title-medium {
    font-size: 20px;
    line-height: 1.7;
    font-weight: 400;

    &.bold {
        font-weight: 700;
    }

    &.semi-bold {
        font-weight: 600;
    }

    &.medium {
        font-weight: 500;
    }
}

.title-small {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 400;

    &.semi-bold {
        font-weight: 600;
    }

    &.medium {
        font-weight: 500;
    }
}

.title-extra-small {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    
    &.semi-bold {
        font-weight: 600;
    }

    &.medium {
        font-weight: 500;
    }
}

p {
    color: $paragraph-text-color;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;

    &.light {
        color: $paragraph-light;
    }

    &.black {
        color: $black;
    }

    &.semi-bold {
        font-weight: 600;
    }

    &.medium {
        font-weight: 500;
    }

    &.small {
        font-size: 14px;
    }

    &.description {
        font-size: 12px;
        color: $stroke-dark;
    }

    &.description-small {
        font-size: 10;
    }

    span {
        font-size: 14px;
    }

    &.with-line {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 12px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $stroke-medium;
        }

        span {
            position: relative;
            background-color: $white;
            padding: 0 1rem;
        }
    }
}

span {
    &.semi-bold {
        font-weight: 600;
    }

    &.medium {
        font-weight: 500;
    }
}
a {
    word-wrap: break-word;
}

b {
    font-weight: 700;

    &.semi-bold {
        font-weight: 600;
    }

    &.medium {
        font-weight: 500;
    }
}

ul.list {
    font-size: 1.5rem;
    padding: 0;
    list-style: none;

    li {
        position: relative;
        padding: 0 0 0 2rem;

        &:before {
            content: '';
            position: absolute;
            top: 0.5rem;
            left: 0;
            width: 1rem;
            height: 1rem;
            background-image: url(../assets/icons/check-green.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
        }
    }

    &.grey-dots {
        padding-left: 1rem;
        li {
            padding: 0 0 0 1rem;

            &:before {
                top: 10px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $paragraph-text-color;
                background-image: none;
            }
        }
    }
}

.primary {
    color: $primary;
}

.stroke-primary {
    color: $stroke-primary;
}

.on-background {
    color: $on-background;
}

.on-surface {
    color: $on-surface;
}

.on-teriary-container {
    color: $on-teriary-container;
}

.paragraph-text {
    color: $paragraph-text-color;
}

.black {
    color: $black;
}

.stroke-dark {
    color: $stroke-dark;
}

.text-prewrap {
    white-space: pre-wrap;
}
