.page-template-hydration
{
    .hydration-goal{
        position: relative;
        margin: 0 0 3rem 0;
        z-index: 10;
    }

    .btn-goal{
        display: inline-block;
        font-size: 16px;
        line-height: 1.5;
        padding: 8px 16px;
        border: none;
        background: $primary-container;
        color: $on-surface !important;
        text-transform: none;
        font-weight: normal;
        width: auto;
        height: auto;
        line-height: normal;

        &:hover, &:active, &:focus{
            box-shadow: none !important;
            background: $primary-container !important;
        }

        .your-goal{
            color: $primary;
        }

        .SVGInline{
            font-size: 16px;
            margin: 0 0 0 4rem;
            color: $on-primary-container;

        }

        &.btn-placeholder {
            background: $light-grey;
            background-image: linear-gradient(
                to right,
                $light-grey 0%,
                darken($light-grey, 4%) 20%,
                $light-grey 40%,
                $light-grey 100%
            );
            background-repeat: no-repeat;
            background-size: 960px 480px;
            border: none;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeholderShimmer;
            animation-timing-function: linear;
    
            color: transparent !important;

            .your-goal{
                color: transparent;
            }
        }
    }

    .add-btn{
        padding: 7px 16px 7px 14px;

        &.btn-placeholder{
            img{
                opacity: 0;
            }
        }
    }

    .delete{
        text-transform: none;
        font-size: 16px;
        font-weight: 500;
        text-decoration: underline;
        
        &:disabled{
            background: transparent;
            opacity: 0;
        }
    }

    .hydration-chart{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 200px;
        margin: 2rem auto 3rem auto;
        text-align: center;

        &::before{
            content: '';
            background: $primary-container;
            width: 200px;
            height: 200px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            border-radius: 50%;
        }

        &::after{
            content: '';
            background: $white;
            width: 152px;
            height: 152px;
            position: absolute;
            top: 24px;
            left: 24px;
            z-index: 0;
            border-radius: 50%;
        }

        .svg-donut {
            position: absolute;
            top: 0;
            left: 0;
            transform: scaleX(-1) rotate(-90deg);
        }
        .donut{
            stroke: $on-primary-container;
            stroke-linecap: round;
            transition: stroke-dasharray 1s ease-in-out;
            animation: donut 3s;
        }
        
        @keyframes donut {
            0% { stroke-dasharray: 0, 900; }
            100% { stroke-dasharray: var(--endvalue), 900; }
        }

        .text{
            font-size: 1rem;
            color: $paragraph-light;
            position: relative;
            z-index: 10;

            .current-value{
                font-size: 24px;
                font-weight: 600;
                color: $on-background;
            }
        }
        
        &.placeholder{
            border-radius: 50%;
        }
    }
}

.modal-dialog.hydration{
    .close{
        opacity: 1;
        font-weight: 300;
    }

    .modal-content{
        border-radius: 10px;
        border: none;

        .modal-header{
            border-bottom: none;
        }

        .modal-body{
            padding: 0 20px;
        }

        .modal-footer{
            border-top: none;
            justify-content: center;
            padding: 20px;
        }
    }

    .custom-range {
        position: relative;
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 46px;
        outline: none;
        padding: 0 !important;
    
        &::-webkit-slider-runnable-track {
            width: 100%;
            height: 10px;
            cursor: pointer;

            background: linear-gradient(
                to right,
                $primary 0%,
                $primary var(--value),
                $primary-container var(--value),
                $primary-container 100%
            );
            border-radius: 5px;
        }
    
        &::-moz-range-track {
            width: 100%;
            height: 10px;
            cursor: pointer;
            background: linear-gradient(
                to right,
                $primary 0%,
                $primary var(--value),
                $primary-container var(--value),
                $primary-container 100%
            );
            border-radius: 5px;
        }
    
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 24px;
            height: 24px;
            background: $white;
            border: 6px solid $primary;
            cursor: pointer;
            margin-top: -8px;
        }
    
        &::-moz-range-thumb {
            width: 22px;
            height: 22px;
            background: $white;
            border: 6px solid $primary;
            cursor: pointer;
            margin-top: -10px;
        }
    
        &::before {
            content: attr(min) " " attr(unit);
            position: absolute;
            left: 0;
            bottom: -0.5rem;
            font-size: 16px;
            color: $on-surface;
        }
    
        &::after {
            content: attr(max) " " attr(unit);
            position: absolute;
            right: 0;
            bottom: -0.5rem;
            font-size: 16px;
            color: $on-surface;
        }
    }
}
