.page-template-diet-settings {
    main {
        padding: 0;
        min-height: 100vh;

        @include media-breakpoint-up(sm) {
            min-height: auto;
        }
        .full-screen {
            display: flex;
            width: 100%;
            min-height: 100vh;
            padding-bottom: 4rem;
            flex-direction: column;
    
            @include media-breakpoint-up(sm) {
                padding-top: 4rem;
                min-height: auto;
            }
    
            &.container{
                min-height: calc(100vh - 3rem);
            }

            &.grey-gradient{
                background: linear-gradient(180deg, #EEEEEE 0%, #FFFFFF 100%);
            }
        }

        .diet-settings-bottom-push {
            height: rem-calc(98);

            @include media-breakpoint-up(sm) {
                display: none;
            }
        }

        .full-screen2 {
            min-height: 70vh;
            display: flex;

            @include media-breakpoint-up(sm) {
                min-height: auto;
                margin-top: 0;
            }
        }

        .full-screen3 {
            margin-top: rem-calc(-98);
            min-height: calc(100vh - 98px - 52px);
            display: flex;

            @include media-breakpoint-up(sm) {
                min-height: auto;
                margin-top: 0;
            }
        }

        .action-buttons {
            position: sticky;
            top: calc(100vh - 90px);
            z-index: 10;

            width: 100%;
            left: 0;
            padding: 2rem 0 1rem 0;
            background-image: linear-gradient(
                to bottom,
                rgba($white, 0) 0%,
                $white 25%,
                $white 100%
            );

            @include media-breakpoint-up(sm) {
                display: none;
            }
        }

        .action-buttons2 {
            display: none;

            @include media-breakpoint-up(sm) {
                display: block;
                position: relative;
                top: initial;
                padding: 0;
                background-image: none;
                margin-top: 2rem;
            }
        }

        .close {
            position: absolute;
            top: 10px;
            right: 10px;
        }

        p {
            &.advice {
                padding: 0 0 0 2rem;
                background-position: 0 0;
                background-repeat: no-repeat;
                margin-top: 56px;

                background-image: url('../../assets/icons/info.svg');
            }
        }

        header {
            margin: 1rem 0 2rem 0;
        }

        .progress-bar-mlf {
            text-align: center;

            .item {
                display: inline-block;
                position: relative;
                width: 20px;
                height: 6px;
                border-radius: $border-radius;
                background: rgba($primary, 0.15);
                margin: 0 4px;
                overflow: hidden;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0;
                    height: 100%;
                    transition: width 0.3s ease-in-out;
                    background: $primary;
                    border-radius: 3px;
                }

                &.active {
                    &:after {
                        width: 100%;
                    }
                }
            }
        }

        .hide {
            opacity: 0;
        }

        .user-setting-parameter-parameter {
            display: flex;
            justify-content: space-between;
            margin: 0 0 0.5rem 0;

            &:last-child {
                margin: 0;
            }

            p.left {
                color: $black;
                font-weight: 500;
                white-space: nowrap;
            }

            p.right {
                color: $paragraph-text-color;
                font-weight: 400;
            }
        }
    }
}

.modal-body {
    button {
        transform: translate(50%, -50%);
    }
}
