.page-template-measurement {
    .header-bg{
        position: sticky;
        top: 0;
        text-align: center;
        background-color: $light-grey;
        z-index: 0;
    }

    header{
        margin: 1rem 0 0 0;
    }

    hr{
        border-top: 1px solid $stroke-primary;
        margin: 2rem 0;
    }

    a:hover{
        text-decoration: none;
    }

    table {
        font-size: 1rem;
        cursor: pointer;
        color: $on-background;

        &.table{
            th, td{
                border-top: 1px solid $background-variant;
            }
            tr:first-child{
                th, td{
                    border-top: none;
                }
            }
        } 

        th {
            font-weight: normal;
            color: $paragraph-text-color
        }

        a {
            color: inherit;
        }

        .edit-icon {
            color: $secondary;
            font-size: 1rem;
        }

        .change-up {
            color: $error;
        }

        .change-down {
            color: $success;
        }

        .no-change {
            color: $paragraph-light;
        }

        a:hover {
            text-decoration: none;
        }
    }

    .deleteMeasurment {
        padding: 0;
        color: $error;
        text-align: center;
        display: block;
        margin: 1rem auto 0 auto;

        height: auto;
        max-width: none;
        background: none;
        border: none;
        text-transform: inherit;
        font-weight: inherit;
        appearance: none;

        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }
}
