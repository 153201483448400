.modal-content {
    &.modal-calendar {
        width: 80%;
        margin: 0 auto;
        overflow: hidden;
        border-radius: 25px;
        background: none;

        @include media-breakpoint-down(sm) {
            width: 90%;
        }
    }
}

.calendar {
    position: relative;

    .calendar-header {
        font-size: 1.1rem;
        color: $white;
        padding: 1rem;
        background: $primary-gradient;

        @include media-breakpoint-down(sm) {
            font-size: 0.9rem;
        }
    }

    .calendar-body {
        background: $white;
        padding: 15px;
        padding-top: 0px;
        .navigation {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .date {
                font-weight: 500;
                color: $black;
                font-size: 1.3rem;
                @include media-breakpoint-down(sm) {
                    font-size: 1rem;
                }

                button {
                    padding-left: 10px;
                }
            }

            button {
                font-size: 2rem;
                color: $primary;

                @include media-breakpoint-down(sm) {
                    font-size: 1.6rem;
                }

                svg {
                    width: 0.7rem;

                    @include media-breakpoint-down(xs) {
                        width: 0.6rem;
                    }
                }
            }

            .month-changer {
                button {
                    font-weight: 300;
                    &.prev-month {
                        padding-right: 1.4rem;
                    }

                    &.next-month {
                        padding-left: 1.4rem;
                    }
                }
            }
        }

        .calendar-week-day {
            height: 40px;
            font-size: 1.1rem;
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            font-weight: 600;
            color: $grey;
            @include media-breakpoint-down(sm) {
                font-size: 0.9rem;
            }

            div {
                display: grid;
                place-items: center;
            }
        }

        .calendar-days {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            gap: 2px;
            place-items: center;

            @include media-breakpoint-down(xs) {
                gap: 1px;
            }

            div {
                box-sizing: content-box;
                position: relative;
                cursor: pointer;
                position: relative;
                width: 75%;
                padding-top: 75%;

                p {
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                }
            }

            .calendar-day {
                font-weight: 500;
                border: 1px solid transparent;

                p {
                    font-size: 1.1rem;
                    color: $black;

                    @include media-breakpoint-down(xs) {
                        font-size: 0.8rem;
                    }
                }

                &.disabled {
                    pointer-events: none;

                    p {
                        color: $grey;
                    }
                }

                &.active {
                    background-color: $primary;
                    border-radius: 50%;

                    p {
                        color: $white;
                    }
                }

                &.current {
                    border-radius: 50%;
                    border: 1px solid $primary;
                }
            }
        }
    }

    .calendar-footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        font-size: 1.4rem;
        border-top: 1px solid $light-grey;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        background: $white;

        @include media-breakpoint-down(xs) {
            font-size: 1rem;
        }

        button {
            color: $primary;
        }
    }
    button {
        border: none;
        background: none;
    }

    .calendar-year-view {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .calendar-year-view-header {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.4rem;
            color: $white;
            padding: 1.3rem;
            background: $primary-gradient;

            @include media-breakpoint-down(sm) {
                font-size: 1rem;
            }

            button {
                position: relative;
                top: 50%;
                transform: translateY(-56%);
                padding: 0 15px;
                color: $white;
                font-size: 1.7rem;

                @include media-breakpoint-down(sm) {
                    font-size: 1.3rem;
                }
            }
        }

        .calendar-year-view-body {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(4, 1fr);
            background-color: $white;
            width: 100%;
            flex-grow: 1;

            span {
                border-top: 1px solid $light-grey;
                border-left: 1px solid $light-grey;

                color: $black;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.5rem;

                @include media-breakpoint-down(sm) {
                    font-size: 1.2rem;
                }

                &:hover {
                    background-color: $light-grey;
                }

                &:nth-child(1) {
                    border: none;
                }
                &:nth-child(2) {
                    border-top: none;
                }
                &:nth-child(3) {
                    border-top: none;
                }
                &:nth-child(4) {
                    border-left: none;
                }
                &:nth-child(6) {
                    border-right: none;
                }
                &:nth-child(7) {
                    border-left: none;
                    border-bottom: 1px solid $light-grey;
                }
                &:nth-child(8) {
                    border-bottom: 1px solid $light-grey;
                }
                &:nth-child(9) {
                    border-bottom: 1px solid $light-grey;
                }

                &:nth-last-child(1) {
                    border-top: 0;
                    border-right: 1px solid $light-grey;
                    grid-column: 2;
                    grid-row: 4;
                }

                &.active {
                    color: $primary;
                    font-weight: 500;
                }

                &.disabled {
                    color: $grey;
                    pointer-events: none;
                }
            }
        }

        &.narrow {
            @include media-breakpoint-up(sm) {
                grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
            }
        }
    }

    .calendar-month-view {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $white;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, 1fr);

        span {
            border-top: 1px solid $light-grey;
            border-left: 1px solid $light-grey;
            color: $black;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.5rem;

            @include media-breakpoint-down(xs) {
                font-size: 1rem;
            }

            &:hover {
                background-color: $light-grey;
            }

            &.current {
                font-weight: 700;
                font-size: 2.2rem;
                color: $green;

                @include media-breakpoint-down(sm) {
                    font-size: 2rem;
                }
            }

            &.active {
                color: $primary;
                font-weight: 500;
            }
        }
    }
}
