.btn {
    &.btn-primary {
        position: relative;
        background-color: $background-variant-2;
        background-image: $primary-gradient;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
        color: $on-primary;
        border: none;
        border-radius: $border-radius;
        padding: 10px 36px;
        transition: all 0.3s;
        vertical-align: top;

        .spinner-border {
            border: 2px solid $white;
            border-right-color: transparent;
            vertical-align: middle;
        }

        &.btn-sm {
            font-size: 14px;
            padding: 7.5px 32px;
        }

        &:disabled {
            &:hover {
                background-image: $primary-gradient;
            }
        }

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        .show > &.dropdown-toggle {
            color: #fff;
            background-color: $background-variant-2;
            border-color: transparent;
        }
    }

    &.btn-outline-primary {
        position: relative;
        background-color: transparent;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
        color: $primary;
        border: 2px solid $primary;
        border-radius: $border-radius;
        padding: 8px 34px;
        transition: all 0.3s;

        &.btn-sm {
            font-size: 14px;
            padding: 5.5px 32px;
        }

        &:disabled {
            &:hover {
                background-image: $primary-gradient;
            }
        }

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        .show > &.dropdown-toggle {
            color: $primary;
            background-color: $primary-container;
        }
    }

    &.btn-link {
        text-decoration: underline;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
        color: $primary;
        padding: 8px;
        border: none;

        &.btn-sm {
            font-size: 14px;
            padding: 7.5px;
        }

        &:disabled{
            visibility: hidden;
        }
    }

    &.btn-transparent {
        position: relative;
        background-color: transparent;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
        color: $paragraph-text-color;
        border-radius: $border-radius;
        padding: 5px 24px;
        transition: all 0.3s;

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        .show > &.dropdown-toggle {
            background-color: $white;
            color: $black;
        }
    }

    &.btn-text {
        background: none;
        outline: none;
        border: none;
        font-size: 14px;
        color: $primary;
        padding: 0;
    }

    // &.btn-white{
    //     background: $white;
    //     font-size: 18px;
    //     font-weight: bold;
    //     line-height: 1.5;
    //     color: $primary;
    //     border: 2px solid transparent;
    //     border-radius: $border-radius;
    //     padding: 6px 20px;
    // }

    // &.btn-outline-white{
    //     background: transparent;
    //     font-size: 18px;
    //     font-weight: bold;
    //     line-height: 1.5;
    //     color: $white;
    //     border: 2px solid $white;
    //     border-radius: $border-radius;
    //     padding: 6px 20px;

    //     &:active{
    //         background-color: transparent !important;
    //         border-color: $white !important;
    //         color: $white !important;
    //     }
    // }

    &.btn-social {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5;
        border-radius: 20px;
        padding: 6px 10px 6px 36px;
        display: block;
        margin: 0.5rem auto;
        width: 18rem;
        font-family: Arial, Helvetica, sans-serif;
    }

    &.facebook {
        position: relative;
        background: #4267b2;
        color: $white;
        border: 1px solid #4267b2;

        &::before {
            position: absolute;
            content: '';
            background-image: url(../assets/icons/button_facebook_logo.svg);
            background-position: left 10px center;
            background-repeat: no-repeat;
            top: 50%;
            transform: translate(-40px, -50%);
            width: 38px;
            height: 38px;
        }
    }

    &.google {
        position: relative;
        background: $white;
        color: $black;
        border: 1px solid $black;

        &::before {
            position: absolute;
            content: '';
            background-image: url(../assets/icons/button_google_logo.svg);
            background-position: left 10px center;
            background-repeat: no-repeat;
            top: 50%;
            transform: translate(-40px, -50%);
            width: 38px;
            height: 38px;
        }
    }

    &.apple {
        position: relative;
        background: $black;
        color: $white;
        border: 1px solid $black;

        &::before {
            position: absolute;
            content: '';
            background-image: url(../assets/icons/button_apple_logo.svg);
            background-position: left 10px center;
            background-repeat: no-repeat;
            top: 50%;
            transform: translate(-40px, -50%);
            width: 38px;
            height: 38px;
        }
    }

    &.prev {
        background: none;
        border: none;

        &:active {
            background-color: transparent !important;
        }
    }

    &.btn-arrow{
        padding: 0;
        position: relative;
        color: $black;
        background-color: $white;
        font-weight: 300;
        border-radius: $border-radius;
        text-align: left;

        .SVGInline {
            color: $primary;
            float: right;
        }
    }

    &.btn-placeholder {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        border: none;
        border-radius: $border-radius;
        padding: 12px 20px;
        transition: all 0.3s;
        border: none;

        background: $light-grey;
        background-image: linear-gradient(
            to right,
            $light-grey 0%,
            darken($light-grey, 4%) 20%,
            $light-grey 40%,
            $light-grey 100%
        );
        background-repeat: no-repeat;
        background-size: 960px 480px;

        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeholderShimmer;
        animation-timing-function: linear;

        color: transparent;
    }

    &:focus {
        box-shadow: none !important;
    }
}
