.developer-options {
    position: fixed;
    right: 0;
    top: 30px;
    z-index: 1000;
    margin-right: -300px;
    transition: margin-right 0.5s ease-in-out;
    pointer-events: none;

    @include media-breakpoint-up(sm) {
        top: 100px;
    }

    &.show{
        margin-right: 0;

        .developer-options__button{
            img{
                transform: rotate(-180deg);
            }
        }
    }

    .developer-options__button{
        position: relative;
        border: none;
        padding: 10px;
        border-radius: 10px 0 0 10px;
        background-color: $light-grey;
        vertical-align: top;
        border-top: 1px solid $grey;
        border-left: 1px solid $grey;
        border-bottom: 1px solid $grey;
        margin-right: -1px;
        z-index: 10;
        pointer-events: auto;

        img{
            width: 30px;
            height: 30px;
            transition: transform 0.5s ease-in-out;
        }
    }

    .developer-options__content{
        position: relative;
        display: inline-block;
        background-color: $light-grey;
        padding: 20px;
        width: 300px;
        border-radius: 0 0 0 10px;
        border-top: 1px solid $grey;
        border-bottom: 1px solid $grey;
        border-left: 1px solid $grey;
        pointer-events: auto;
    }
}
